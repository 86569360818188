@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face{
  font-family: 'Pretendard';
  src: url("/fonts/Pretendard-Bold.subset.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face{
  font-family: 'Pretendard';
  src: url("/fonts/Pretendard-SemiBold.subset.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face{
  font-family: 'Pretendard';
  src: url("/fonts/Pretendard-Medium.subset.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face{
  font-family: 'Pretendard';
  src: url("/fonts/Pretendard-Regular.subset.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Gotham';
  src: url("/fonts/Gotham-Bold_Web.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face{
  font-family: 'Gotham';
  src: url("/fonts/Gotham-Medium_Web.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face{
  font-family: 'Gotham';
  src: url("/fonts/Gotham-Book_Web.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face{
  src: url("/fonts/HeirofLightBold.woff") format("woff");
  font-family: 'HeirofLight';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face{
  src: url("/fonts/HeirofLightRegular.woff") format("woff");
  font-family: 'HeirofLight';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
html,body {
  color: #222222;
  height: 100%;
}

a:focus,
button:focus,
input:focus,
:focus {
  outline: none;
}

a::-moz-focus-inner,
button::-moz-focus-inner,
input::-moz-focus-inner,
::-moz-focus-inner {
  border: 0;
}

.Toastify__toast {
  min-height:48px;
}

/**
*
* react-select 디자인 override
*
*/
.react-select-container {
  border: none;
  box-sizing: border-box;
}

.react-select-container .react-select__control {
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

/* is-focused, menu is-open, hover */
.react-select-container .react-select__control--is-focused,
.react-select-container .react-select__control.react-select__control--is-focused.react-select__control--menu-is-open,
.react-select-container .react-select__control--is-hover,
.react-select-container .react-select__control:hover {
  border: 1px solid #737373;
  box-shadow: none;
  background: #fff;
}

.react-select-container .react-select__value-container {
  padding: 12px;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
}

.react-select-container .react-select__value-container {
  position: relative;
}

.react-select__value-container>div {
  height: 20px !important;
  box-sizing: border-box !important;
  margin: 0 2px !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

#react-select-2-input {
  box-shadow: none !important;
}

.react-select-container .react-select__value-container .react-select__placeholder {
  color: #222222;
}

.react-select-container .react-select__control .react-select__indicators .react-select__indicator-separator.css-1okebmr-indicatorSeparator {
  width: 0;
}

.react-select__indicator-separator {
  display: none;
}

.react-select-container .react-select__control .react-select__indicators .react-select__indicator.react-select__dropdown-indicator {
  color: #222222;
  padding-right: 12px;
}

.react-select-container .react-select__menu {
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
  left: -1px;
  box-sizing: border-box;
  box-shadow: none;
  overflow: hidden;
}

.react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 10px;
  height: 100px;
}

.react-select__menu-list::-webkit-scrollbar-track {
  margin-top: 8px;
  margin-bottom: 8px;
  background: #fff;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background: #D4D4D4;
  border-radius: 2px;
  border-right: 8px white solid;
  background-clip: padding-box;
}

.react-select__menu .react-select__menu-list div:not(:last-child)>div {
  border-bottom: 1px solid #D4D4D4;
  box-sizing: border-box;
}

.react-select-container.react-select--is-disabled .react-select__control.react-select__control--is-disabled .react-select__placeholder {
  color: #737373;
}

.react-select__menu-list .react-select__menu-notice--no-options {
  padding: 16px;
  text-align: left;
  font-size: 14px;
  color: #737373;
}

@media all and (max-width: 768px) {
  .react-select__menu-list .react-select__menu-notice--no-options {
    padding: 12px 16px;
    font-size: 12px;
  }
}

@media all and (max-width:1023px) {
  .react-select-container {
    width: 100%;
    flex-shrink: 0;
  }

  .react-select-container .react-select__value-container {
    padding: 8px;
  }

  .react-select-container .react-select__control .react-select__indicators .react-select__indicator.react-select__dropdown-indicator {
    padding-right: 13px;
  }
}

/* main page hero fade */
.slick-slide {
  z-index: 998;
}

@media (min-width:1280px) {
  .hero-banner.slick-list {
    margin-right: -24px !important;
  }
}

.slick-active.slick-current {
  z-index: 999;
}

.openPathCarousel li.slick-active span {
  background: #444444 !important;
}

#CourseContainer {
  display: flex !important;
}

#CourseContainer>article {
  width: calc(25% - 18px) !important;
}

/* toast */
.Toastify__toast-icon {
  width: 22px !important;
  height: 22px !important;
  margin-right: 8px !important;
}
#aiToast .Toastify__toast-icon {
  width: auto !important;
  height:auto !important;
}
.Toastify--animate {
  animation-duration: 0.3s !important;
}
.Toastify__toast-container {
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}

/* bitmovin */
.bmpui-ui-watermark {
  display: none;
}

.univ-event-slide .slick-slide {
  padding-right:16px;
}
.univ-event-slide .slick-list {
  margin-right: -16px;
}

.home-kdc-slide .slick-slide {
  padding-right:16px;
}
.home-kdc-slide .slick-list {
  margin-right: -16px;
}

/* 멋사스쿨 홈 리뷰 슬라이더 */
.kdt-review-slider .slick-slide {
  padding-right: 24px;
}

.kdt-review-slider .slick-list {
  margin-right: -23px;
}

.kdt-review-slider .slick-next,
.kdt-review-slider .slick-prev {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.kdt-review-slider .slick-next:hover,
.kdt-review-slider .slick-prev:hover {
  background: #fff;
}

.kdt-review-slider .slick-next {
  right: -64px;
}

.kdt-review-slider .slick-next::before {
  content: "";
}

.kdt-review-slider .slick-prev {
  left: -64px;
}

.kdt-review-slider .slick-prev::before {
  content: "";
}

.kdt-review-slider .slick-next.slick-disabled,
.kdt-review-slider .slick-prev.slick-disabled {
  display: none !important;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .kdt-review-slider .slick-next {
    z-index: 90;
    right: -16px;
    transition: all 0.3s;
  }

  .kdt-review-slider .slick-prev {
    z-index: 90;
    left: -16px;
    transition: all 0.3s;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .kdt-review-slider .slick-list {
    overflow: initial;
    margin-right: 30px;
  }

  .kdt-review-slider .slick-next,
  .kdt-review-slider .slick-prev {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .kdt-review-slider .slick-list {
    overflow: initial;
    margin-right: 140px;
  }

  .kdt-review-slider .slick-next,
  .kdt-review-slider .slick-prev {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .kdt-review-slider .slick-list {
    overflow: initial !important;
    margin-right: 35px;
  }

  .kdt-review-slider .slick-next,
  .kdt-review-slider .slick-prev {
    display: none !important;
  }
}


.kdc-guide-accordion .mantine-Accordion-icon {
  width: 24px !important;
  min-width: 24px !important;
}

.kdc-guide-accordion .mantine-Accordion-icon > svg {
  width: 24px !important;
}

.Toastify__toast-body {
  flex: initial;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

/* 추천강의 슬라이더 */
.relation-course-slider .slick-slide {
  margin-right: 16px;
}

.relation-course-slider .slick-track {
  width: 0;
  max-width: 0vw;
  min-width: 100%;
  margin-right: -16x;
  display: flex !important;
}

.relation-course-slider .slick-list{
  margin-right: -16x;
}

.relation-course-slider .slick-next,
.relation-course-slider .slick-prev {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  width: 48px;
  height: 48px;
  top: 40%;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.relation-course-slider .slick-next:hover,
.relation-course-slider .slick-prev:hover {
  background: #fff;
}

.relation-course-slider .slick-prev::before,
.relation-course-slider .slick-next::before {
  content: "";
}

.relation-course-slider .slick-next.slick-disabled,
.relation-course-slider .slick-prev.slick-disabled {
  display: none !important;
}

@media (max-width: 1023px) {
  .relation-course-slider .slick-next,
  .relation-course-slider .slick-prev {
    display: none !important;
  }

  .relation-course-slider .slick-slide {
    margin-right: 8px;
  }
  .relation-course-slider .slick-list {
    margin-right: -8px;
  }
}

@media (max-width: 1279px) {
  .relation-course-slider .slick-next,
  .relation-course-slider .slick-prev {
    width: 40px;
    height: 40px;
    top: 30%;
  }
}