.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  overflow:hidden;
  display:flex;
  align-items:center;
}
.react-datepicker__input-container input {
  border: none;
  padding: 12px;
  font-size: 16px;
  width: 100%;
}
.react-datepicker__input-container input::placeholder {
  color: #D4D4D4;
}

.react-datepicker {
  font-size: .8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: relative;
  display:flex;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #aeaeae;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__current-month {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 4px;
  font-weight: 500;
  flex: 1 1 0%;
  text-align: center;
  color: #000;
}
.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 14px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__triangle{
  display:none;
}

@media (max-width: 767px) {
  .react-datepicker__input-container input {
    font-size: 14px;
  }
}